// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { TreeSelectTokens } from './type';

const treeSelectTokens: TreeSelectTokens = {
  treeSelectFontSize: 'Font-14',
  treeSelectFontColor: 'Neutral-text-5',
  treeSelectBorderRadius: 'Radius-default',
  treeSelectBorderColor: 'Neutral-border-2',
  treeSelectPaddingX: 'Spacing-8',
  treeSelectPaddingY: 'Spacing-2',
  treeSelectIconSize: 'Size-7',
  treeSelectIconColor: 'Neutral-text-4',
  treeSelectIconDisabledColor: 'Neutral-text-2',
  treeSelectSmallFontSize: 'Font-12',
  treeSelectSmallPaddingX: 'Spacing-8',
  treeSelectSmallPaddingY: 'Spacing-1',
  treeSelectSmallOptionInnerPaddingX: 'Spacing-8',
  treeSelectSmallOptionInnerPaddingY: 'Spacing-2',
  treeSelectLargeFontSize: 'Font-16',
  treeSelectLargePaddingX: 'Spacing-12',
  treeSelectLargePaddingY: 'Spacing-3',
  treeSelectLargeOptionInnerPaddingX: 'Spacing-12',
  treeSelectLargeOptionInnerPaddingY: 'Spacing-8',
  treeSelectLargePlaceholderMarginY: 'Spacing-4',
  treeSelectInnerPaddingX: 'Spacing-8',
  treeSelectInnerPaddingY: 'Spacing-5',
  treeSelectPlaceholderColor: 'Neutral-text-2',
  treeSelectPlaceholderMarginY: 'Spacing-2',
  treeSelectBackgroundColor: 'Neutral-fill-1',
  treeSelectClearColor: 'Neutral-text-3',
  treeSelectClearPadding: 'Spacing-8',
  treeSelectClearHoverColor: 'Neutral-text-4',
  treeSelectHoverBorderColor: 'Brand-6',
  treeSelectHoverClearColor: 'Neutral-text-4',
  treeSelectFocusBorderColor: 'Brand-7',
  treeSelectFocusShadow: 'Brand-2',
  treeSelectDisabledFontColor: 'Neutral-text-2',
  treeSelectDisabledPlaceholderColor: 'Neutral-text-2',
  treeSelectDisabledBorderColor: 'Neutral-border-2',
  treeSelectDisabledBackgroundColor: 'Neutral-fill-2',
  treeSelectResultTextBorderRadius: 'Radius-default',
  treeSelectResultTextPaddingX: 'Spacing-4',
  treeSelectResultTextSmallPaddingX: 'Spacing-4',
  treeSelectResultTextLargePaddingX: 'Spacing-10',
  treeSelectResultTextActiveBackgroundColor: 'Brand-1',
  treeSelectErrorBorderColor: 'Danger-6',
  treeSelectErrorFocusShadow: 'Danger-1',
  treeSelectPanelColor: 'Neutral-text-5',
  treeSelectPanelBackgroundColor: 'Neutral-fill-1',
  treeSelectPanelShadow: 'Shadow-2',
  treeSelectPanelRadius: 'Radius-default',
  treeSelectPanelBorder: 'Neutral-border-1',
  treeSelectContentHoverFontColor: 'Neutral-text-5',
  treeSelectContentHoverBackgroundColor: 'Neutral-fill-2',
  treeSelectContentActiveFontColor: 'Brand-6',
  treeSelectContentActiveBackgroundColor: 'Brand-1',
  treeSelectContentDisabledFontColor: 'Neutral-text-2',
  treeSelectContentDisabledBackgroundColor: 'Transprent',
  treeSelectOptionHeight: 'Size-16',
  treeSelectOptionPaddingX: 'Spacing-4',
  treeSelectOptionPaddingY: 'Spacing-1',
  treeSelectOptionActiveColor: 'Brand-6',
  treeSelectOptionActiveBackgroundColor: 'Brand-1',
  treeSelectOptionFontColor: 'Neutral-text-5',
  treeSelectOptionInnerPaddingX: 'Spacing-8',
  treeSelectOptionInnerPaddingY: 'Spacing-5',
  treeSelectOptionInnerPaddingRight: 'Size-15',
  treeSelectOptionInnerBorderRadius: 'Radius-lesser',
  treeSelectOptionHoverBackgroundColor: 'Neutral-fill-2',
  treeSelectOptionDisabledColor: 'Neutral-text-2',
  treeSelectOptionDisabledBackgroundColor: 'Neutral-fill-1',
  treeSelectMorePaddingX: 'Spacing-8',
  treeSelectMorePaddingY: 'Spacing-6',
  treeSelectHeaderPadding: 'Spacing-8',
  treeSelectHeaderBorderColor: 'Neutral-border-1',
  treeSelectGroupTitleFontColor: 'Neutral-text-3',
  treeSelectGroupTitleFontSize: 'Font-12',
  treeSelectGroupTitlePaddingX: 'Spacing-12',
  treeSelectGroupTitlePaddingY: 'Spacing-6',
  treeSelectColumnPadding: 'Spacing-8',
  treeSelectColumnOptionMargin: 'Spacing-8',
};

export default treeSelectTokens;
