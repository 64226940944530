// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { SelectTokens } from './type';

const selectTokens: SelectTokens = {
  selectFontSize: 'Font-14',
  selectFontColor: 'Neutral-text-5',
  selectFontWeight: 'Weight-regular',
  selectBorderRadius: 'Radius-default',
  selectBorderColor: 'Neutral-border-2',
  selectBorderWidth: 'Border-1',
  selectPaddingX: 'Spacing-8',
  selectPaddingY: 'Spacing-2',
  selectIconSize: 'Size-7',
  selectIconColor: 'Neutral-text-4',
  selectIconMarginLeft: 'Spacing-8',
  selectTagMarginY: 'Spacing-2',
  selectTagMarginRight: 'Spacing-4',
  selectSmallFontSize: 'Font-12',
  selectSmallPaddingX: 'Spacing-8',
  selectSmallPaddingY: 'Spacing-1',
  selectSmallOptionInnerPaddingX: 'Spacing-8',
  selectSmallOptionInnerPaddingY: 'Spacing-2',
  selectLargeFontSize: 'Font-16',
  selectLargePaddingX: 'Spacing-12',
  selectLargePaddingY: 'Spacing-3',
  selectLargeOptionInnerPaddingX: 'Spacing-12',
  selectLargeOptionInnerPaddingY: 'Spacing-8',
  selectLargePlaceholderMarginY: 'Spacing-4',
  selectInnerPaddingX: 'Spacing-8',
  selectInnerPaddingY: 'Spacing-5',
  selectPlaceholderColor: 'Neutral-text-2',
  selectPlaceholderMarginY: 'Spacing-2',
  selectBackgroundColor: 'Neutral-fill-1',
  selectClearColor: 'Neutral-text-3',
  selectClearPadding: 'Spacing-8',
  selectHoverBorderColor: 'Brand-6',
  selectHoverClearColor: 'Neutral-text-4',
  selectHoverBackgroundColor: 'Neutral-fill-1',
  selectFocusBorderColor: 'Brand-7',
  selectFocusShadow: 'Brand-2',
  selectFocusBackgroundColor: 'Neutral-fill-1',
  selectDisabledIconColor: 'Neutral-text-2',
  selectDisabledFontColor: 'Neutral-text-2',
  selectDisabledPlaceholderColor: 'Neutral-text-2',
  selectDisabledBorderColor: 'Neutral-border-1',
  selectDisabledBackgroundColor: 'Neutral-fill-2',
  selectResultTextBorderRadius: 'Radius-default',
  selectResultTextPaddingX: 'Spacing-4',
  selectResultTextSmallPaddingX: 'Spacing-4',
  selectResultTextLargePaddingX: 'Spacing-10',
  selectResultTextActiveBackgroundColor: 'Brand-1',
  selectErrorBorderColor: 'Danger-6',
  selectErrorBackgroundColor: 'Neutral-fill-1',
  selectErrorFocusShadow: 'Danger-1',
  selectErrorFocusBackgroundColor: 'Neutral-fill-1',
  selectErrorFocusBorderColor: 'Danger-6',
  selectErrorHoverBackgroundColor: 'Neutral-fill-1',
  selectErrorHoverBorderColor: 'Danger-6',
  selectPanelColor: 'Neutral-text-5',
  selectPanelBackgroundColor: 'Neutral-fill-1',
  selectPanelShadow: 'Shadow-2',
  selectPanelRadius: 'Radius-default',
  selectPanelBorder: 'Neutral-border-1',
  selectOptionHeight: 'Size-16',
  selectOptionBackgroundColor: 'Neutral-fill-1',
  selectOptionFontColor: 'Neutral-text-5',
  selectOptionPaddingX: 'Spacing-4',
  selectOptionPaddingY: 'Spacing-1',
  selectOptionActiveColor: 'Brand-6',
  selectOptionActiveBackgroundColor: 'Brand-1',
  selectOptionInnerPaddingX: 'Spacing-8',
  selectOptionInnerPaddingY: 'Spacing-5',
  selectOptionInnerPaddingRight: 'Size-15',
  selectOptionInnerBorderRadius: 'Radius-lesser',
  selectOptionHoverBackgroundColor: 'Neutral-fill-2',
  selectOptionHoverFontColor: 'Neutral-text-5',
  selectOptionDisabledColor: 'Neutral-text-2',
  selectOptionDisabledBackgroundColor: 'Neutral-fill-1',
  selectMorePaddingX: 'Spacing-8',
  selectMorePaddingY: 'Spacing-6',
  selectHeaderPaddingX: 'Spacing-8',
  selectHeaderPaddingY: 'Spacing-8',
  selectHeaderBorderColor: 'Neutral-border-1',
  selectGroupTitleFontColor: 'Neutral-text-3',
  selectGroupTitleFontSize: 'Font-12',
  selectGroupTitleFontWeight: 'Weight-regular',
  selectGroupTitlePaddingX: 'Spacing-12',
  selectGroupTitlePaddingTop: 'Spacing-10',
  selectGroupTitlePaddingBottom: 'Spacing-2',
  selectGroupTitleSmallX: 'Spacing-8',
  selectGroupTitleSmallTop: 'Spacing-4',
  selectGroupTitleLargeX: 'Spacing-12',
  selectGroupTitleLargeTop: 'Spacing-14',
  selectGroupTitleLargeBottom: 'Spacing-4',
  selectColumnPadding: 'Spacing-8',
  selectColumnOptionMargin: 'Spacing-8',
  selectColumnBorderRadius: 'Radius-default',
  selectColumnBackgroundColor: 'Neutral-fill-2',
  selectLoadingSpinColor: 'Brand-6',
  selectEmptyFontColor: 'Neutral-text-2',
};

export default selectTokens;
