// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { StickyTokens } from './type';

const stickyTokens: StickyTokens = {
  stickyFontSize: 'Font-14',
};

export default stickyTokens;
