// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { TagTokens } from './type';

const tagTokens: TagTokens = {
  tagInfoFontColor: 'Brand-6',
  tagInfoBackgroundColor: 'Brand-1',
  tagInfoBorderColor: 'Brand-1',
  tagInfoDisabledFontColor: 'Brand-3',
  tagInfoDisabledBackgroundColor: 'Brand-1',
  tagInfoDisabledBorderColor: 'Brand-1',
  tagInfoOutlineFontColor: 'Brand-6',
  tagInfoOutlineBackgroundColor: 'Neutral-fill-1',
  tagInfoOutlineBorderColor: 'Brand-6',
  tagInfoOutlineDisabledFontColor: 'Brand-3',
  tagInfoOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagInfoOutlineDisabledBorderColor: 'Brand-3',
  tagInfoFillFontColor: 'Neutral-text-1',
  tagInfoFillBackgroundColor: 'Brand-6',
  tagInfoFillBorderColor: 'Brand-6',
  tagInfoFillDisabledFontColor: 'Neutral-text-1',
  tagInfoFillDisabledBackgroundColor: 'Brand-3',
  tagInfoFillDisabledBorderColor: 'Brand-3',
  tagDefaultFontColor: 'Neutral-text-5',
  tagDefaultBackgroundColor: 'Neutral-fill-2',
  tagDefaultBorderColor: 'Neutral-fill-2',
  tagDefaultDisabledFontColor: 'Neutral-text-2',
  tagDefaultDisabledBackgroundColor: 'Neutral-fill-2',
  tagDefaultDisabledBorderColor: 'Neutral-fill-2',
  tagDefaultOutlineFontColor: 'Neutral-text-5',
  tagDefaultOutlineBackgroundColor: 'Neutral-fill-1',
  tagDefaultOutlineBorderColor: 'Neutral-border-2',
  tagDefaultOutlineDisabledFontColor: 'Neutral-text-2',
  tagDefaultOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagDefaultOutlineDisabledBorderColor: 'Neutral-border-2',
  tagDefaultFillFontColor: 'Neutral-text-5',
  tagDefaultFillBackgroundColor: 'Neutral-fill-3',
  tagDefaultFillBorderColor: 'Neutral-fill-3',
  tagDefaultFillDisabledFontColor: 'Neutral-text-2',
  tagDefaultFillDisabledBackgroundColor: 'Neutral-fill-3',
  tagDefaultFillDisabledBorderColor: 'Neutral-fill-3',
  tagSuccessFontColor: 'Success-6',
  tagSuccessBackgroundColor: 'Success-1',
  tagSuccessBorderColor: 'Success-1',
  tagSuccessDisabledFontColor: 'Success-3',
  tagSuccessDisabledBackgroundColor: 'Success-1',
  tagSuccessDisabledBorderColor: 'Success-1',
  tagSuccessOutlineFontColor: 'Success-6',
  tagSuccessOutlineBackgroundColor: 'Neutral-fill-1',
  tagSuccessOutlineBorderColor: 'Success-6',
  tagSuccessOutlineDisabledFontColor: 'Success-3',
  tagSuccessOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagSuccessOutlineDisabledBorderColor: 'Success-3',
  tagSuccessFillFontColor: 'Neutral-text-1',
  tagSuccessFillBackgroundColor: 'Success-6',
  tagSuccessFillBorderColor: 'Success-6',
  tagSuccessFillDisabledFontColor: 'Neutral-text-1',
  tagSuccessFillDisabledBackgroundColor: 'Success-3',
  tagSuccessFillDisabledBorderColor: 'Success-3',
  tagWarningFontColor: 'Warning-6',
  tagWarningBackgroundColor: 'Warning-1',
  tagWarningBorderColor: 'Warning-1',
  tagWarningDisabledFontColor: 'Warning-3',
  tagWarningDisabledBackgroundColor: 'Warning-1',
  tagWarningDisabledBorderColor: 'Warning-1',
  tagWarningOutlineFontColor: 'Warning-6',
  tagWarningOutlineBackgroundColor: 'Neutral-fill-1',
  tagWarningOutlineBorderColor: 'Warning-6',
  tagWarningOutlineDisabledFontColor: 'Warning-3',
  tagWarningOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagWarningOutlineDisabledBorderColor: 'Warning-3',
  tagWarningFillFontColor: 'Neutral-text-1',
  tagWarningFillBackgroundColor: 'Warning-6',
  tagWarningFillBorderColor: 'Warning-6',
  tagWarningFillDisabledFontColor: 'Neutral-text-1',
  tagWarningFillDisabledBackgroundColor: 'Warning-3',
  tagWarningFillDisabledBorderColor: 'Warning-3',
  tagDangerFontColor: 'Danger-6',
  tagDangerBackgroundColor: 'Danger-1',
  tagDangerBorderColor: 'Danger-1',
  tagDangerDisabledFontColor: 'Danger-3',
  tagDangerDisabledBackgroundColor: 'Danger-1',
  tagDangerDisabledBorderColor: 'Danger-1',
  tagDangerOutlineFontColor: 'Danger-6',
  tagDangerOutlineBackgroundColor: 'Neutral-fill-1',
  tagDangerOutlineBorderColor: 'Danger-6',
  tagDangerOutlineDisabledFontColor: 'Danger-3',
  tagDangerOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagDangerOutlineDisabledBorderColor: 'Danger-3',
  tagDangerFillFontColor: 'Neutral-text-1',
  tagDangerFillBackgroundColor: 'Danger-6',
  tagDangerFillBorderColor: 'Danger-6',
  tagDangerFillDisabledFontColor: 'Neutral-text-1',
  tagDangerFillDisabledBackgroundColor: 'Danger-3',
  tagDangerFillDisabledBorderColor: 'Danger-3',
  tagMagentaFontColor: 'Magenta-6',
  tagMagentaBackgroundColor: 'Magenta-1',
  tagMagentaBorderColor: 'Magenta-1',
  tagMagentaDisabledFontColor: 'Magenta-3',
  tagMagentaDisabledBackgroundColor: 'Magenta-1',
  tagMagentaDisabledBorderColor: 'Magenta-1',
  tagMagentaOutlineFontColor: 'Magenta-6',
  tagMagentaOutlineBackgroundColor: 'Neutral-fill-1',
  tagMagentaOutlineBorderColor: 'Magenta-6',
  tagMagentaOutlineDisabledFontColor: 'Magenta-3',
  tagMagentaOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagMagentaOutlineDisabledBorderColor: 'Magenta-3',
  tagMagentaFillFontColor: 'Neutral-text-1',
  tagMagentaFillBackgroundColor: 'Magenta-6',
  tagMagentaFillBorderColor: 'Magenta-6',
  tagMagentaFillDisabledFontColor: 'Neutral-text-1',
  tagMagentaFillDisabledBackgroundColor: 'Magenta-3',
  tagMagentaFillDisabledBorderColor: 'Magenta-3',
  tagBrownFontColor: 'Orange-6',
  tagBrownBackgroundColor: 'Orange-1',
  tagBrownBorderColor: 'Orange-1',
  tagBrownDisabledFontColor: 'Orange-3',
  tagBrownDisabledBackgroundColor: 'Orange-1',
  tagBrownDisabledBorderColor: 'Orange-1',
  tagBrownOutlineFontColor: 'Orange-6',
  tagBrownOutlineBackgroundColor: 'Neutral-fill-1',
  tagBrownOutlineBorderColor: 'Orange-6',
  tagBrownOutlineDisabledFontColor: 'Orange-3',
  tagBrownOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagBrownOutlineDisabledBorderColor: 'Orange-3',
  tagBrownFillFontColor: 'Neutral-text-1',
  tagBrownFillBackgroundColor: 'Orange-6',
  tagBrownFillBorderColor: 'Orange-6',
  tagBrownFillDisabledFontColor: 'Neutral-text-1',
  tagBrownFillDisabledBackgroundColor: 'Orange-3',
  tagBrownFillDisabledBorderColor: 'Orange-3',
  tagPurpleFontColor: 'Purple-6',
  tagPurpleBackgroundColor: 'Purple-1',
  tagPurpleBorderColor: 'Purple-1',
  tagPurpleDisabledFontColor: 'Purple-3',
  tagPurpleDisabledBackgroundColor: 'Purple-1',
  tagPurpleDisabledBorderColor: 'Purple-1',
  tagPurpleOutlineFontColor: 'Purple-6',
  tagPurpleOutlineBackgroundColor: 'Neutral-fill-1',
  tagPurpleOutlineBorderColor: 'Purple-6',
  tagPurpleOutlineDisabledFontColor: 'Purple-3',
  tagPurpleOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagPurpleOutlineDisabledBorderColor: 'Purple-3',
  tagPurpleFillFontColor: 'Neutral-text-1',
  tagPurpleFillBackgroundColor: 'Purple-6',
  tagPurpleFillBorderColor: 'Purple-6',
  tagPurpleFillDisabledFontColor: 'Neutral-text-1',
  tagPurpleFillDisabledBackgroundColor: 'Purple-3',
  tagPurpleFillDisabledBorderColor: 'Purple-3',
  tagIndigoFontColor: 'Indigo-6',
  tagIndigoBackgroundColor: 'Indigo-1',
  tagIndigoBorderColor: 'Indigo-1',
  tagIndigoDisabledFontColor: 'Indigo-3',
  tagIndigoDisabledBackgroundColor: 'Indigo-1',
  tagIndigoDisabledBorderColor: 'Indigo-1',
  tagIndigoOutlineFontColor: 'Indigo-6',
  tagIndigoOutlineBackgroundColor: 'Neutral-fill-1',
  tagIndigoOutlineBorderColor: 'Indigo-6',
  tagIndigoOutlineDisabledFontColor: 'Indigo-3',
  tagIndigoOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagIndigoOutlineDisabledBorderColor: 'Indigo-3',
  tagIndigoFillFontColor: 'Neutral-text-1',
  tagIndigoFillBackgroundColor: 'Indigo-6',
  tagIndigoFillBorderColor: 'Indigo-6',
  tagIndigoFillDisabledFontColor: 'Neutral-text-1',
  tagIndigoFillDisabledBackgroundColor: 'Indigo-3',
  tagIndigoFillDisabledBorderColor: 'Indigo-3',
  tagCyanFontColor: 'Cyan-6',
  tagCyanBackgroundColor: 'Cyan-1',
  tagCyanBorderColor: 'Cyan-1',
  tagCyanDisabledFontColor: 'Cyan-3',
  tagCyanDisabledBackgroundColor: 'Cyan-1',
  tagCyanDisabledBorderColor: 'Cyan-1',
  tagCyanOutlineFontColor: 'Cyan-6',
  tagCyanOutlineBackgroundColor: 'Neutral-fill-1',
  tagCyanOutlineBorderColor: 'Cyan-6',
  tagCyanOutlineDisabledFontColor: 'Cyan-3',
  tagCyanOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagCyanOutlineDisabledBorderColor: 'Cyan-3',
  tagCyanFillFontColor: 'Neutral-text-1',
  tagCyanFillBackgroundColor: 'Cyan-6',
  tagCyanFillBorderColor: 'Cyan-6',
  tagCyanFillDisabledFontColor: 'Neutral-text-1',
  tagCyanFillDisabledBackgroundColor: 'Cyan-3',
  tagCyanFillDisabledBorderColor: 'Cyan-3',
  tagNeonFontColor: 'Neon-6',
  tagNeonBackgroundColor: 'Neon-1',
  tagNeonBorderColor: 'Neon-1',
  tagNeonDisabledFontColor: 'Neon-3',
  tagNeonDisabledBackgroundColor: 'Neon-1',
  tagNeonDisabledBorderColor: 'Neon-1',
  tagNeonOutlineFontColor: 'Neon-6',
  tagNeonOutlineBackgroundColor: 'Neutral-fill-1',
  tagNeonOutlineBorderColor: 'Neon-6',
  tagNeonOutlineDisabledFontColor: 'Neon-3',
  tagNeonOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagNeonOutlineDisabledBorderColor: 'Neon-3',
  tagNeonFillFontColor: 'Neutral-text-1',
  tagNeonFillBackgroundColor: 'Neon-6',
  tagNeonFillBorderColor: 'Neon-6',
  tagNeonFillDisabledFontColor: 'Neutral-text-1',
  tagNeonFillDisabledBackgroundColor: 'Neon-3',
  tagNeonFillDisabledBorderColor: 'Neon-3',
  tagLemonFontColor: 'Lemon-6',
  tagLemonBackgroundColor: 'Lemon-1',
  tagLemonBorderColor: 'Lemon-1',
  tagLemonDisabledFontColor: 'Lemon-3',
  tagLemonDisabledBackgroundColor: 'Lemon-1',
  tagLemonDisabledBorderColor: 'Lemon-1',
  tagLemonOutlineFontColor: 'Lemon-6',
  tagLemonOutlineBackgroundColor: 'Neutral-fill-1',
  tagLemonOutlineBorderColor: 'Lemon-6',
  tagLemonOutlineDisabledFontColor: 'Lemon-3',
  tagLemonOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagLemonOutlineDisabledBorderColor: 'Lemon-3',
  tagLemonFillFontColor: 'Neutral-text-1',
  tagLemonFillBackgroundColor: 'Lemon-6',
  tagLemonFillBorderColor: 'Lemon-6',
  tagLemonFillDisabledFontColor: 'Neutral-text-1',
  tagLemonFillDisabledBackgroundColor: 'Lemon-3',
  tagLemonFillDisabledBorderColor: 'Lemon-3',
  tagTangerineFontColor: 'Tangerine-6',
  tagTangerineBackgroundColor: 'Tangerine-1',
  tagTangerineBorderColor: 'Tangerine-1',
  tagTangerineDisabledFontColor: 'Tangerine-3',
  tagTangerineDisabledBackgroundColor: 'Tangerine-1',
  tagTangerineDisabledBorderColor: 'Tangerine-1',
  tagTangerineOutlineFontColor: 'Tangerine-6',
  tagTangerineOutlineBackgroundColor: 'Neutral-fill-1',
  tagTangerineOutlineBorderColor: 'Tangerine-6',
  tagTangerineOutlineDisabledFontColor: 'Tangerine-3',
  tagTangerineOutlineDisabledBackgroundColor: 'Neutral-fill-1',
  tagTangerineOutlineDisabledBorderColor: 'Tangerine-3',
  tagTangerineFillFontColor: 'Neutral-text-1',
  tagTangerineFillBackgroundColor: 'Tangerine-6',
  tagTangerineFillBorderColor: 'Tangerine-6',
  tagTangerineFillDisabledFontColor: 'Neutral-text-1',
  tagTangerineFillDisabledBackgroundColor: 'Tangerine-3',
  tagTangerineFillDisabledBorderColor: 'Tangerine-3',
  tagFontSize: 'Font-12',
  tagBorderRadius: 'Radius-small',
  tagPaddingX: 'Spacing-6',
  tagPaddingY: 'Spacing-0',
  tagHeight: 'Size-11',
  tagFontWeight: 'Weight-regular',
  tagSmallFontSize: 'Font-12',
  tagSmallBorderRadius: 'Radius-small',
  tagSmallPaddingX: 'Spacing-4',
  tagSmallHeight: 'Size-10',
  tagSmallFontWeight: 'Weight-regular',
  tagLargeFontSize: 'Font-14',
  tagLargeBorderRadius: 'Radius-default',
  tagLargePaddingX: 'Spacing-12',
  tagLargePaddingY: 'Spacing-2',
  tagLargeHeight: 'Size-14',
  tagLargeFontWeight: 'Weight-regular',
  tagInputPaddingX: 'Spacing-4',
  tagInputFontSize: 'Font-12',
  tagSmallInputPaddingX: 'Spacing-4',
  tagSmallInputFontSize: 'Font-12',
  tagLargeInputPaddingX: 'Spacing-4',
  tagLargeInputFontSize: 'Font-14',
  tagInfoIconFontColor: 'Brand-6',
  tagInfoIconDisabledFontColor: 'Brand-3',
  tagInfoIconHoverBackgroundColor: 'Brand-2',
  tagInfoOutlineIconDisabledFontColor: 'Brand-3',
  tagInfoOutlineIconHoverBackgroundColor: 'Brand-1',
  tagInfoOutlineIconHoverFontColor: 'Brand-6',
  tagDefaultIconFontColor: 'Neutral-text-4',
  tagDefaultIconDisabledFontColor: 'Neutral-text-2',
  tagDefaultIconHoverBackgroundColor: 'Neutral-fill-3',
  tagDefaultIconHoverFontColor: 'Neutral-text-5',
  tagSuccessIconFontColor: 'Success-6',
  tagSuccessIconDisabledFontColor: 'Success-3',
  tagSuccessIconHoverBackgroundColor: 'Success-2',
  tagSuccessOutlineIconDisabledFontColor: 'Success-3',
  tagSuccessOutlineIconHoverBackgroundColor: 'Success-2',
  tagWarningIconDisabledFontColor: 'Warning-3',
  tagWarningIconHoverBackgroundColor: 'Warning-2',
  tagWarningFillIconFontColor: 'Warning-6',
  tagDangerIconFontColor: 'Danger-6',
  tagDangerIconDisabledFontColor: 'Danger-3',
  tagDangerIconHoverBackgroundColor: 'Danger-2',
  tagMagentaIconFontColor: 'Magenta-6',
  tagMagentaIconDisabledFontColor: 'Magenta-3',
  tagMagentaIconHoverBackgroundColor: 'Magenta-2',
  tagBrownIconFontColor: 'Orange-6',
  tagBrownIconDisabledFontColor: 'Orange-3',
  tagBrownIconHoverBackgroundColor: 'Orange-2',
  tagPurpleIconFontColor: 'Purple-6',
  tagPurpleIconDisabledFontColor: 'Purple-3',
  tagPurpleIconHoverBackgroundColor: 'Purple-2',
  tagIndigoIconFontColor: 'Indigo-6',
  tagIndigoIconDisabledFontColor: 'Indigo-3',
  tagIndigoIconHoverBackgroundColor: 'Indigo-2',
  tagCyanIconFontColor: 'Cyan-6',
  tagCyanIconDisabledFontColor: 'Cyan-3',
  tagCyanIconHoverBackgroundColor: 'Cyan-2',
  tagNeonIconFontColor: 'Neon-6',
  tagNeonIconDisabledFontColor: 'Neon-3',
  tagNeonIconHoverBackgroundColor: 'Neon-2',
  tagLemonIconFontColor: 'Lemon-6',
  tagLemonIconDisabledFontColor: 'Lemon-3',
  tagLemonIconHoverBackgroundColor: 'Lemon-2',
  tagTangerineIconFontColor: 'Tangerine-6',
  tagTangerineIconDisabledFontColor: 'Tangerine-3',
  tagTangerineIconHoverBackgroundColor: 'Tangerine-2',
};

export default tagTokens;
