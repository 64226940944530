// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { TabsTokens } from './type';

const tabsTokens: TabsTokens = {
  tabsFontColor: 'Neutral-text-5',
  tabsBackgroundColor: 'Neutral-fill-2',
  tabsBorderColor: 'Neutral-border-1',
  tabsActiveFontColor: 'Brand-6',
  tabsActiveBackgroundColor: 'Neutral-fill-1',
  tabsHoverBackgroundColor: 'Neutral-fill-2',
  tabsDisabledFontColor: 'Neutral-text-2',
  tabsDisabledBackgroundColor: 'Neutral-fill-1',
  tabsClickBackgroundColor: 'Neutral-fill-4',
  tabsTabPaddingX: 'Spacing-15',
  tabsTabPaddingY: 'Spacing-9',
  tabsTabFontSize: 'Font-14',
  tabsTabBorderRadius: 'Radius-default',
  tabsNearlyMargin: 'Spacing-4',
  tabsLinePaddingX: 'Spacing-8',
  tabsLinePaddingY: 'Spacing-6',
  tabsLineHrBackgroundColor: 'Neutral-border-1',
  tabsLineAfterBackgroundColor: 'Brand-6',
  tabsLineInnerPaddingX: 'Spacing-8',
  tabsLineInnerPaddingY: 'Spacing-4',
  tabsLineFontColor: 'Neutral-text-5',
  tabsLineFontWeight: 'Weight-regular',
  tabsLineFontSize: 'Font-14',
  tabsLineBackgroundColor: 'transparent',
  tabsLineActiveBackgroundColor: 'Neutral-fill-3',
  tabsLineCheckedFontColor: 'Brand-6',
  tabsLineCheckedFontSize: 'Font-14',
  tabsLineCheckedFontWeight: 'Weight-medium',
  tabsLineCheckedBackgroundColor: 'transparent',
  tabsLineHoverFontSize: 'Font-14',
  tabsLineHoverBackgroundColor: 'Neutral-fill-2',
  tabsLineDisabledFontColor: 'Neutral-text-2',
  tabsFillPaddingX: 'Spacing-4',
  tabsFillPaddingY: 'Spacing-4',
  tabsFillInnerPaddingX: 'Spacing-16',
  tabsFillInnerPaddingY: 'Spacing-5',
  tabsFillFontColor: 'Neutral-text-5',
  tabsFillFontWeight: 'Weight-regular',
  tabsFillFontSize: 'Font-14',
  tabsFillBackgroundColor: 'Neutral-fill-2',
  tabsFillActiveFontWeight: 'Weight-medium',
  tabsFillActiveFontSize: 'Font-14',
  tabsFillActiveBackgroundColor: 'Neutral-fill-4',
  tabsFillCheckedFontColor: 'Brand-6',
  tabsFillCheckedFontSize: 'Font-14',
  tabsFillCheckedFontWeight: 'Weight-medium',
  tabsFillCheckedBackgroundColor: 'Neutral-fill-1',
  tabsFillHoverBackgroundColor: 'Neutral-fill-3',
  tabsFillDisabledFontColor: 'Neutral-text-2',
  tabsDashFontWeight: 'Weight-regular',
  tabsDashFontSize: 'Font-14',
  tabsDashActiveFontWeight: 'Weight-medium',
  tabsDashActiveFontSize: 'Font-14',
  tabsCardPaddingX: 'Spacing-15',
  tabsCardPaddingY: 'Spacing-9',
  tabsCardFontColor: 'Neutral-text-5',
  tabsCardFontWeight: 'Weight-regular',
  tabsCardFontSize: 'Font-14',
  tabsCardBackgroundColor: 'Neutral-fill-2',
  tabsCardActiveFontWeight: 'Weight-medium',
  tabsCardActiveFontSize: 'Font-14',
  tabsCardActiveBackgroundColor: 'Neutral-fill-4',
  tabsCardBorderColor: 'Neutral-border-1',
  tabsCardCheckedFontColor: 'Brand-6',
  tabsCardCheckedFontSize: 'Font-14',
  tabsCardCheckedFontWeight: 'Weight-medium',
  tabsCardCheckedBackgroundColor: 'Neutral-fill-1',
  tabsCardHoverBackgroundColor: 'Neutral-fill-3',
  tabsCardDisabledFontColor: 'Neutral-text-2',
  tabsCardDisabledBackgroundColor: 'Neutral-fill-2',
  tabsSplitBorderColor: 'Neutral-border-2',
  tabsActionVerticalPaddingX: 'Spacing-7',
  tabsActionVerticalPaddingY: 'Spacing-4',
  tabsActionHorizontalPaddingX: 'Spacing-7',
  tabsActionHorizontalPaddingY: 'Spacing-8',
  tabsExtraFillHoverBackgroundColor: 'Neutral-fill-3',
  tabsArrowFontColor: 'Neutral-text-4',
};

export default tabsTokens;
