// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { CascaderTokens } from './type';

const cascaderTokens: CascaderTokens = {
  cascaderFontSize: 'Font-14',
  cascaderFontColor: 'Neutral-text-5',
  cascaderBorderRadius: 'Radius-default',
  cascaderBorderColor: 'Neutral-border-2',
  cascaderPaddingX: 'Spacing-8',
  cascaderPaddingY: 'Spacing-2',
  cascaderIconSize: 'Size-7',
  cascaderIconColor: 'Neutral-text-4',
  cascaderSmallFontSize: 'Font-12',
  cascaderSmallPaddingX: 'Spacing-8',
  cascaderSmallPaddingY: 'Spacing-1',
  cascaderSmallOptionInnerPaddingX: 'Spacing-8',
  cascaderSmallOptionInnerPaddingY: 'Spacing-2',
  cascaderLargeFontSize: 'Font-16',
  cascaderLargePaddingX: 'Spacing-12',
  cascaderLargePaddingY: 'Spacing-3',
  cascaderLargeOptionInnerPaddingX: 'Spacing-12',
  cascaderLargeOptionInnerPaddingY: 'Spacing-8',
  cascaderLargePlaceholderMarginY: 'Spacing-4',
  cascaderInnerPaddingX: 'Spacing-8',
  cascaderInnerPaddingY: 'Spacing-5',
  cascaderPlaceholderColor: 'Neutral-text-2',
  cascaderPlaceholderMarginY: 'Spacing-2',
  cascaderBackgroundColor: 'Neutral-fill-1',
  cascaderClearColor: 'Neutral-text-3',
  cascaderClearPadding: 'Spacing-8',
  cascaderClearHoverColor: 'Neutral-text-4',
  cascaderHoverBorderColor: 'Brand-6',
  cascaderHoverClearColor: 'Neutral-text-4',
  cascaderFocusBorderColor: 'Brand-7',
  cascaderFocusShadow: 'Brand-2',
  cascaderDisabledFontColor: 'Neutral-text-2',
  cascaderDisabledPlaceholderColor: 'Neutral-text-2',
  cascaderDisabledBorderColor: 'Neutral-border-2',
  cascaderDisabledBackgroundColor: 'Neutral-fill-2',
  cascaderResultTextBorderRadius: 'Radius-default',
  cascaderResultTextPaddingX: 'Spacing-4',
  cascaderResultTextSmallPaddingX: 'Spacing-4',
  cascaderResultTextLargePaddingX: 'Spacing-10',
  cascaderResultTextActiveBackgroundColor: 'Brand-1',
  cascaderResultItemHoverColor: 'Brand-5',
  cascaderResultItemActiveColor: 'Brand-7',
  cascaderResultItemCheckedColor: 'Brand-6',
  cascaderErrorBorderColor: 'Danger-6',
  cascaderErrorFocusShadow: 'Danger-1',
  cascaderPanelColor: 'Neutral-text-5',
  cascaderPanelBackgroundColor: 'Neutral-fill-1',
  cascaderPanelShadow: 'Shadow-2',
  cascaderPanelRadius: 'Radius-default',
  cascaderPanelBorder: 'Neutral-border-1',
  cascaderOptionHeight: 'Size-16',
  cascaderOptionFontColor: 'Neutral-text-5',
  cascaderOptionBackgroundColor: 'Neutral-fill-1',
  cascaderOptionPaddingX: 'Spacing-4',
  cascaderOptionPaddingY: 'Spacing-1',
  cascaderOptionActiveColor: 'Brand-6',
  cascaderOptionActiveBackgroundColor: 'Brand-1',
  cascaderOptionInnerPaddingX: 'Spacing-8',
  cascaderOptionInnerPaddingY: 'Spacing-5',
  cascaderOptionInnerPaddingRight: 'Size-15',
  cascaderOptionInnerBorderRadius: 'Radius-lesser',
  cascaderOptionIconColor: 'Neutral-text-4',
  cascaderOptionHoverBackgroundColor: 'Neutral-fill-2',
  cascaderOptionHoverFontColor: 'Neutral-text-5',
  cascaderOptionDisabledColor: 'Neutral-text-2',
  cascaderOptionDisabledBackgroundColor: 'Neutral-fill-1',
  cascaderMorePaddingX: 'Spacing-16',
  cascaderMorePaddingY: 'Spacing-14',
  cascaderHeaderPadding: 'Spacing-8',
  cascaderHeaderBorderColor: 'Neutral-border-1',
  cascaderGroupTitleFontColor: 'Neutral-text-3',
  cascaderGroupTitleFontSize: 'Font-12',
  cascaderGroupTitlePaddingX: 'Spacing-12',
  cascaderGroupTitlePaddingY: 'Spacing-6',
  cascaderColumnPadding: 'Spacing-8',
  cascaderColumnOptionMargin: 'Spacing-8',
  cascaderListBorderColor: 'Neutral-border-1',
};

export default cascaderTokens;
