export const renderNoData = () => {
  return (
    <svg
      width='100'
      height='75'
      viewBox='0 0 100 75'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4044_10434)'>
        <path
          d='M100 75C100 63.3607 77.6144 53.9257 50 53.9257C22.3857 53.9257 0 63.3607 0 75H100Z'
          fill='url(#paint0_linear_4044_10434)'
        />
        <g opacity='0.3' filter='url(#filter0_f_4044_10434)'>
          <path
            d='M50.3087 70.0413C69.5706 70.0413 85.185 68.2375 85.185 66.0125C85.185 63.7875 69.5706 61.9835 50.3087 61.9835C31.0469 61.9835 15.4321 63.7875 15.4321 66.0125C15.4321 68.2375 31.0469 70.0413 50.3087 70.0413Z'
            fill='#979EB0'
          />
        </g>
        <path
          d='M22.6082 23.6395C22.782 23.2127 23.1957 22.9338 23.6548 22.9338H75.7168C76.1718 22.9338 76.5818 23.2071 76.7593 23.6275L82.9493 38.3482H16.6184L22.6082 23.6395Z'
          fill='#CCCFD7'
        />
        <path d='M75.9819 23.9172V37.2382H81.2882L75.9819 23.9172Z' fill='#999DA8' />
        <path
          d='M18.877 37.0161H81.0701C82.6314 37.0161 83.897 38.2871 83.897 39.8547V61.7805C83.897 64.2888 81.872 66.3225 79.3739 66.3225H20.5732C18.0752 66.3225 16.05 64.2888 16.05 61.7805V39.8547C16.05 38.2871 17.3157 37.0161 18.877 37.0161Z'
          fill='#E8EBF0'
        />
        <g filter='url(#filter1_d_4044_10434)'>
          <path
            d='M16.0493 42.3192C16.0493 39.8108 16.0019 37.715 20.573 37.7774H79.3737C83.5612 37.7774 83.8968 39.8108 83.8968 42.3192V61.3998C83.8968 63.9087 81.8718 65.9419 79.3737 65.9419H20.573C18.075 65.9419 16.0493 63.9087 16.0493 61.3998V42.3192Z'
            fill='black'
          />
        </g>
        <path
          d='M16.0493 42.3192C16.0493 39.8108 16.0019 37.715 20.573 37.7774H79.3737C83.5612 37.7774 83.8968 39.8108 83.8968 42.3192V61.3998C83.8968 63.9087 81.8718 65.9419 79.3737 65.9419H20.573C18.075 65.9419 16.0493 63.9087 16.0493 61.3998V42.3192Z'
          fill='#F4F5F8'
        />
        <path d='M23.4412 23.8855V37.0162H18.3242L23.4412 23.8855Z' fill='#999DA8' />
        <path
          d='M60.3971 50.7178H39.5502C38.0849 50.7178 36.897 51.9106 36.897 53.382C36.897 54.8534 38.0849 56.0462 39.5502 56.0462H60.3971C61.8625 56.0462 63.0501 54.8534 63.0501 53.382C63.0501 51.9106 61.8625 50.7178 60.3971 50.7178Z'
          fill='#666C7C'
        />
        <path
          d='M60.3971 49.9565H39.5502C38.0849 49.9565 36.897 51.1494 36.897 52.6208C36.897 54.0922 38.0849 55.285 39.5502 55.285H60.3971C61.8625 55.285 63.0501 54.0922 63.0501 52.6208C63.0501 51.1494 61.8625 49.9565 60.3971 49.9565Z'
          fill='#999DA8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M46.041 3.14062C42.6319 3.14062 39.8682 5.91575 39.8682 9.33899V11.4859C39.8682 14.9092 42.6319 17.6842 46.041 17.6842H54.8002C58.2093 17.6842 60.9731 14.9092 60.9731 11.4859V9.33899C60.9731 5.91575 58.2093 3.14062 54.8002 3.14062H46.041ZM47.3169 21.0086L49.386 17.6842H45.2478L47.3169 21.0086Z'
          fill='#E8EBF0'
        />
        <path d='M45.6834 8.54285L47.4941 11.6922H43.8726L45.6834 8.54285Z' fill='#999DA8' />
        <path d='M52.304 8.54285H49.1677V11.6921H52.304V8.54285Z' fill='#999DA8' />
        <path
          d='M55.6802 11.6919C56.5463 11.6919 57.2484 10.9869 57.2484 10.1172C57.2484 9.2476 56.5463 8.5426 55.6802 8.5426C54.8142 8.5426 54.1121 9.2476 54.1121 10.1172C54.1121 10.9869 54.8142 11.6919 55.6802 11.6919Z'
          fill='#999DA8'
        />
        <path
          d='M67.7256 5.8507C66.5081 6.69694 63.5181 6.751 62.3457 7.09731L63.5875 5.64293C64.415 4.6041 64.6631 4.10547 65.6562 2.11091C66.6493 0.116362 69.3118 -0.105256 70.415 0.0332553C69.7943 1.27985 70.415 3.98081 67.7256 5.8507Z'
          fill='#E8EBF0'
        />
        <path
          d='M46.257 34.8904C45.3444 35.1307 43.5421 34.5087 42.7649 34.4595L43.824 33.8603C44.5451 33.4193 44.802 33.175 45.8298 32.198C46.8574 31.221 48.4998 31.6709 49.1305 31.9953C48.4883 32.606 48.2737 34.3593 46.257 34.8904Z'
          fill='#E8EBF0'
        />
        <path
          d='M37.262 32.7723C37.13 32.5427 37.2462 32.2485 37.4982 32.1696C39.7646 31.4593 49.3015 28.2658 55.6537 22.9713C62.0347 17.653 67.6725 11.576 69.1256 9.9791C69.3069 9.77991 69.6263 9.81841 69.76 10.0521C69.845 10.1989 69.8294 10.3803 69.7169 10.507C68.6225 11.7406 62.8044 18.1953 56.0675 23.6911C48.7371 29.6708 39.4667 32.4671 37.7105 32.9602C37.5349 33.0096 37.3532 32.9308 37.262 32.7723Z'
          fill='#E8EBF0'
        />
        <path
          d='M26.0283 27.1925C25.977 26.9505 26.1514 26.7154 26.396 26.6852C27.3291 26.5701 29.6586 26.1971 33.309 25.0577C37.011 23.9021 40.6908 21.2137 41.9723 20.2199C42.1914 20.05 42.5167 20.1675 42.5743 20.4394C42.6023 20.572 42.5585 20.7071 42.454 20.793C41.598 21.497 37.5626 24.6932 33.4811 25.8705C29.5763 26.9969 27.2876 27.3842 26.4592 27.5011C26.2561 27.5298 26.0709 27.394 26.0283 27.1925Z'
          fill='#E8EBF0'
        />
      </g>
      <defs>
        <filter
          id='filter0_f_4044_10434'
          x='9.43213'
          y='55.9835'
          width='81.7529'
          height='20.0577'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='3' result='effect1_foregroundBlur_4044_10434' />
        </filter>
        <filter
          id='filter1_d_4044_10434'
          x='16.0493'
          y='37.776'
          width='67.8474'
          height='30.1659'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.803922 0 0 0 0 0.811765 0 0 0 0 0.843137 0 0 0 1 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4044_10434' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4044_10434'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_4044_10434'
          x1='50'
          y1='53.9257'
          x2='50'
          y2='75'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E9EBEF' />
          <stop offset='1' stopColor='#E9EBEF' stopOpacity='0' />
        </linearGradient>
        <clipPath id='clip0_4044_10434'>
          <rect width='100' height='75' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
