// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { EmptyTokens } from './type';

const emptyTokens: EmptyTokens = {
  emptyFontSize: 'Font-14',
  emptyFontColor: 'Neutral-text-3',
  emptyFontWeight: 'Weight-regular',
};

export default emptyTokens;
