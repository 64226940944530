// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { EditableAreaTokens } from './type';

const editableAreaTokens: EditableAreaTokens = {
  editableAreaFontSize: 'Font-14',
};

export default editableAreaTokens;
