// 此文件由脚本自动生成，请勿直接修改。
// This file was generated automatically by a script. Please do not modify it directly.

import { CardGroupTokens } from './type';

const cardGroupTokens: CardGroupTokens = {
  cardGroupFontSize: 'Font-14',
};

export default cardGroupTokens;
